import styled, { css } from 'styled-components';

import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  flex: 1 1 640;
  width: 100%;
  order: 1;

  ${media.from_tablet`
    background-color: #D3DED3;
    width: 50%;
    order: 1;
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 640;
  order: 2;
  background-color: ${Colors.Primary};
  padding: 32px;

  ${media.from_tablet`
    background-color: #fff;
    padding: 0px;
    width: 50%;
    order: 1;
  `}
`;

export const ContentWrapper = styled.div`
  padding-inline: var(--right-left-global-padding);
  padding-block: 64px;
  max-width: 600px;
`;

export const ImageWrapper = styled.div<{ $isInverted: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ $isInverted }) =>
    $isInverted
      ? css`
          ${media.from_tablet`
            transform: translateX(32px);
          `}
        `
      : css`
          ${media.from_tablet`
            transform: translateX(-32px);
          `}
        `}

  ${media.from_tablet`
    margin: 64px auto;
  `}

  ${media.from_laptop`
    margin: 128px auto;
  `}

  ${media.from_large_laptop`
    margin: 256px auto;
  `}

  img {
    border-radius: 8px;
    outline: 1px solid ${Colors.ContrastLightest};
  }
`;
