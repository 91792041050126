import { TwoColumnsIntroProps } from './TwoColumnsIntro.types';
import { Container, ImageWrapper, ContentContainer, ImageContainer, ContentWrapper } from './TwoColumnsIntro.styles';

export const TwoColumnsIntro = ({
  children,
  image,
  isInverted = false,
}: React.PropsWithChildren<TwoColumnsIntroProps>) => {
  return (
    <Container>
      {isInverted ? (
        <>
          <ImageContainer>
            <ImageWrapper $isInverted={isInverted}>
              <img src={image} />
            </ImageWrapper>
          </ImageContainer>
          <ContentContainer>
            <ContentWrapper>{children}</ContentWrapper>
          </ContentContainer>
        </>
      ) : (
        <>
          <ContentContainer>
            <ContentWrapper>{children}</ContentWrapper>
          </ContentContainer>
          <ImageContainer>
            <ImageWrapper $isInverted={isInverted}>
              <img src={image} />
            </ImageWrapper>
          </ImageContainer>
        </>
      )}
    </Container>
  );
};
